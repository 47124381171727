import "./App.css";
import { React, useState, useEffect } from "react";
import { auth } from "./components/Firebase";
import LoginForm from "./components/LoginForm";
import Item from "./components/Item";
import Container from "./components/Container";

function App() {
  const stateApp = {
    dataToApi: {
      userEmail: "",
      text: "",
      status: false,
    },
    dataFromApi: [],
  };

  const [currentUser, setCurrentUser] = useState(null);
  useEffect(() => {
    auth.onAuthStateChanged((user) => {
      if (user) {
        setState({
          dataToApi: {
            userEmail: user.email,
            text: state.dataToApi.text,
            status: state.dataToApi.status,
          },
          dataFromApi: state.dataFromApi,
        });
        setCurrentUser(user);
        localStorage.setItem("user", user.uid);
      } else {
        setCurrentUser(null);
        localStorage.removeItem("user");
      }
    });
  }, []);

  const handleSignOut = () => {
    auth.signOut();
  };

  const [state, setState] = useState(stateApp);

  const apiUrl = "https://shoplist-6626b-default-rtdb.firebaseio.com/.json";

  const sendDataToApi = () => {
    const options = {
      method: "POST",
      body: JSON.stringify(state.dataToApi),
      headers: {
        "Content-Type": "application/json",
      },
    };

    fetch(apiUrl, options).then(() => loadDataFromApi());
  };

  const loadDataFromApi = () => {
    fetch(apiUrl)
      .then((resp) => {
        if (resp.ok) {
          return resp.json();
        }

        return Promise.reject(resp);
      })
      .then((data) => {
        if (data === null || data === undefined) {
          setState({
            dataToApi: {
              userEmail: state.dataToApi.userEmail,
              text: state.dataToApi.text,
              status: state.dataToApi.status,
            },
            dataFromApi: [],
          });
        } else {
          setState({
            dataToApi: {
              userEmail: state.dataToApi.userEmail,
              text: state.dataToApi.text,
              status: state.dataToApi.status,
            },
            dataFromApi: Object.entries(data),
          });
        }
      });
  };

  const onChangeInput = (e) => {
    console.log(e.target.value);
    const dataFromInput = e.target.value;
    setState({
      dataToApi: {
        userEmail: state.dataToApi.userEmail,
        text: dataFromInput,
        status: state.dataToApi.status,
      },
      dataFromApi: state.dataFromApi,
    });
  };

  const deleteItem = (e) => {
    const itemId = e.target.dataset.id;

    const options = {
      method: "DELETE",
    };

    fetch(
      `https://shoplist-6626b-default-rtdb.firebaseio.com/${itemId}/.json`,
      options
    )
      .then((resp) => console.log(resp))
      .catch((err) => console.log(err))
      .then(() => loadDataFromApi());
  };

  const statusItem = (e) => {
    const itemId = e.target.dataset.id;
    const userEmail = e.target.dataset.email;
    const userText = e.target.dataset.text;

    const itemUpdate = {
      userEmail: userEmail,
      text: userText,
      status: true,
    };

    const options = {
      method: "PUT",
      body: JSON.stringify(itemUpdate),
      headers: {
        "Content-Type": "application/json",
      },
    };

    fetch(
      `https://shoplist-6626b-default-rtdb.firebaseio.com/${itemId}/.json`,
      options
    ).then(() => loadDataFromApi());
  };

  return (
    <>
      {currentUser ? (
        <div className="App">
          <h1>Nev App</h1>
          <button onClick={sendDataToApi}>SEND</button>
          <input onChange={onChangeInput}></input>
          <button onClick={handleSignOut}>Wyloguj</button>
          <button onClick={() => console.log(state)}>
            Pokaż Stan Aplikacji
          </button>
          <button onClick={() => loadDataFromApi()}>LoadData</button>

          <div className="ContainerApp">
            <Container>
              <h2>LISTA</h2>
              {state.dataFromApi.map((ele, index) => {
                return ele[1].userEmail === state.dataToApi.userEmail &&
                  ele[1].status === false ? (
                  <Item status={ele[1].status}>
                    {/* <h2>{ele[1].userEmail}</h2> */}
                    <h2>{ele[1].text}</h2>
                    <button data-id={ele[0]} onClick={deleteItem}>
                      Delete
                    </button>
                    <button
                      data-text={ele[1].text}
                      data-email={ele[1].userEmail}
                      data-id={ele[0]}
                      onClick={statusItem}
                    >
                      Kupione
                    </button>
                  </Item>
                ) : null;
              })}
            </Container>

            <Container>
              <h2>KOSZYK</h2>
              {state.dataFromApi.map((ele, index) => {
                return ele[1].userEmail === state.dataToApi.userEmail &&
                  ele[1].status === true ? (
                  <Item status={ele[1].status}>
                    <h2>Kupione</h2>
                    <h2>{ele[1].text}</h2>
                    <button data-id={ele[0]} onClick={deleteItem}>
                      Delete
                    </button>
                    <button
                      data-text={ele[1].text}
                      data-email={ele[1].userEmail}
                      data-id={ele[0]}
                      onClick={statusItem}
                    >
                      Kupione
                    </button>
                  </Item>
                ) : null;
              })}
            </Container>
          </div>
        </div>
      ) : (
        <LoginForm></LoginForm>
      )}
    </>
  );
}

export default App;
