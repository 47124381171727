// Import the functions you need from the SDKs you need
import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBpt3W3yLvqs2x3CxbEOmnYmQRdV_StHrI",
  authDomain: "shoplist-6626b.firebaseapp.com",
  databaseURL: "https://shoplist-6626b-default-rtdb.firebaseio.com",
  projectId: "shoplist-6626b",
  storageBucket: "shoplist-6626b.appspot.com",
  messagingSenderId: "180701486807",
  appId: "1:180701486807:web:3fb132b41627eb4b441a92"
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig)

export const auth = firebase.auth()
