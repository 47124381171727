import StyledContainer from "./StyledContainer";

const Container = (props) =>{

    const {children} = props

    return (
        <StyledContainer>{children}</StyledContainer>
    )
}

export default Container