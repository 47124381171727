import { React, useReducer } from 'react'
import { auth } from './Firebase'
import Form from './Form'


const LoginForm = () => {
  const [inputsContent, setInputsContent] = useReducer((state, newState) => ({ ...state, ...newState }),
    {
      email: '',
      password: ''
    })

  const { email, password } = inputsContent

  const handleInputChange = e => {
    setInputsContent({
      [e.target.name]: e.target.value
    })
  }

  const handleSignIn = e => {
    e.preventDefault()
    auth
      .signInWithEmailAndPassword(email, password)
      .catch(error =>
        alert(
            `Złe dane!, ${error}`
        ))
  }

  return (
    <Form>
      <input
        placeholder={'E-mail'}
        type={'email'}
        name = {'email'}
        onChange ={handleInputChange}
        value={email}
      >
      </input>
      <input
        placeholder={'Password'}
        type={'password'}
        name = {'password'}
        onChange ={handleInputChange}
        value = {password}
      >
      </input>
      <input
        type={'submit'}
        onClick = {handleSignIn}
        value ={'Zaloguj się'}

      >
      </input>

  
    </Form>
  )
}

export default LoginForm
