import styled from "styled-components";

const StyledItem = styled.div`
  border: 1px solid black;
  border-radius: 10px;
  width: 150px;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: ${(props) =>
    props.status === true ? "#aacc00" : "#eae2b7"};
  padding: 10px;
  margin-top: 30px;
`;

export default StyledItem;
