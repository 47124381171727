import styled from "styled-components";

const StyledContainer = styled.div`

display:flex;
align-items: center;

flex-direction: column;
width: 40vw;

margin: 10px;
padding: 15px;

`

export default StyledContainer