import StyledItem from "./StyledItem";

const Item = (props) => {

 const {children, status} = props

    return(

        <StyledItem status ={status}>{children}</StyledItem>
    )
}

export default Item